<template>
  <v-layout column wrap>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">POINTING SYSTEM REPORT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-2">
        <v-flex xs12 md1>
          <v-checkbox label="Is Yearly?" v-model="is_yearly"
                      @change="on_change_report"></v-checkbox>
        </v-flex>
        <v-flex xs12 md2>
          <v-select
            v-if="is_yearly"
            v-model="month_of"
            class="mx-3 pt-5"
            :items="month_of_items"
            item-value="year"
            item-text="year"
            label="Year Of"
            required
            @change="get_list_of_accomplishment_items"
            :rules="rules.combobox_rule"
          ></v-select>
          <v-select
            v-else
            v-model="month_of"
            class="mx-3 pt-5"
            :items="month_of_items"
            item-value="id"
            item-text="month_of"
            label="Month Of"
            required
            @change="get_list_of_accomplishment_items"
            :rules="rules.combobox_rule"
          ></v-select>
        </v-flex>

        <span class="headline">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
      </v-layout>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              District/Club
            </th>
            <th class="text-center text-uppercase">
              Points for Health
            </th>
            <th class="text-center text-uppercase">
              Points for Education
            </th>
            <th class="text-center text-uppercase">
              Points for Community
            </th>
            <th class="text-center text-uppercase">
              Points for Livelihood
            </th>
            <th class="text-center text-uppercase">
              Points for Youth
            </th>
            <th class="text-center text-uppercase">
              Points for Environment
            </th>
            <th class="text-center text-uppercase">
              Points for Protection
            </th>
            <th class="text-center text-uppercase">
              Total
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in list_of_accomplishment"
            :key="item.id"
          >
            <td>
              {{ item.district_name }}
            </td>
            <td class="text-center">
              {{ item.Health }}
            </td>
            <td class="text-center">
              {{ item.Education }}
            </td>
            <td class="text-center">
              {{ item.Community }}
            </td>
            <td class="text-center">
              {{ item.Livelihood }}
            </td>
            <td class="text-center">
              {{ item.Youth }}
            </td>
            <td class="text-center">
              {{ item.Environment }}
            </td>
            <td class="text-center">
              {{ item.Protection }}
            </td>
            <td class="text-center">
              {{ item.total }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-layout>
</template>

<script>
  import {mapActions, mapGetters,} from 'vuex'
  import moment from "moment";
  import {
    mdiAccountSearchOutline, mdiPrinter, mdiEyeCircle, mdiPencil
    , mdiDelete
  } from '@mdi/js'
  import NewAccomplishment from '../user_interface/register/NewAccomplishment'

  export default {
    components: {
      NewAccomplishment,
    },
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
          mdiPrinter,
          mdiEyeCircle,
          mdiPencil,
          mdiDelete,
        },
      }
    },
    data() {
      return {
        photo1: '',
        photo2: '',
        photo3: '',

        donors_dialog: false,
        volunteers_dialog: false,
        expenses_dialog: false,
        narrative_dialog: false,
        edit_dialog: false,
        photos_dialog: false,
        is_yearly: false,

        month_of: '',
        district: '',
        total_expense: '0.00',
        district_items: [],
        list_of_new_member: [],
        month_of_items: [],
        list_of_accomplishment: [],

        list_of_donors: [],
        list_of_volunteers: [],
        selected_report: {},
        list_of_expenses: [],
        narratives: '',
        value_props: {},
        key: 0,
      }
    },
    mounted() {
      this.retrieve_transaction_month()
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['company_logo']),
      ...mapGetters('authentication', ['branch_id', 'branch', 'branch_address', 'branch_contact_no', 'user_id', 'district_id', 'district_name', 'position']),
    },
    methods: {
      ...mapActions('transaction_months', ['retrieve_transaction_month']),
      ...mapActions('accomplishment', ['get_list_of_accomplishment_pointing_system', 'delete_accomplishment', 'delete_participants']),
      ...mapActions('district', ['list_of_district_have_accomplishment']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      view_photos(value) {
        this.selected_report = value
        this.photo1 = value.photo_1
        this.photo2 = value.photo_2
        this.photo3 = value.photo_3
        this.photos_dialog = true
      },
      view_donors(value) {
        this.list_of_donors = value.donors
        this.donors_dialog = true
      },
      view_participant(value) {
        this.selected_report = value
        this.list_of_volunteers = value.participants
        this.volunteers_dialog = true
      },
      view_expenses(value) {
        this.list_of_expenses = value.expenses_breakdown
        this.expenses_dialog = true
      },
      view_narrative(value) {
        this.narratives = value.narratives
        this.narrative_dialog = true
      },
      edit(value) {
        this.value_props = value
        this.edit_dialog = true
        this.key++
      },
      on_update_details() {
        this.edit_dialog = false
        this.get_list_of_accomplishment_items()
      },
      on_change_report() {
        this.district_items = []
        this.list_of_accomplishment = []
        this.month_of = ''
      },
      get_list_of_accomplishment_items() {
        this.list_of_accomplishment = []
        if (this.month_of_items.length > 0) {
          var index = this.is_yearly ? this.month_of_items.map(function (x) {
            return x.year
          }).indexOf(this.month_of) : this.month_of_items.map(function (x) {
            return x.id
          }).indexOf(this.month_of)
          if (index != -1) {
            const data = new FormData()
            data.append('month_of', this.is_yearly ? this.month_of_items[index].year : this.month_of_items[index].month_of);
            data.append('is_yearly', this.is_yearly ? 1 : 0);
            this.get_list_of_accomplishment_pointing_system(data)
              .then((response) => {
                this.list_of_accomplishment = response.data
              })
              .catch(error => {
                console.log(error)
              })
          }
        }
      },
      delete_volunteerss(value) {
        if (confirm("Are you sure you want to delete participants")) {
          const data = new FormData()
          data.append('id', value.id);
          this.delete_participants(data)
            .then(() => {
              this.get_list_of_accomplishment_items()
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      delete_accomplishmentss(value) {
        if (confirm("Are you sure you want to delete accomplishment")) {
          const data = new FormData()
          data.append('accomplishment_id', value.id);
          this.delete_accomplishment(data)
            .then(() => {
              this.get_list_of_accomplishment_items()
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      print_data() {
        var imgData = this.company_logo
        var array = []
        var indexMonth = this.month_of_items.map(function (x) {
          return x.id
        }).indexOf(this.month_of)

        var month = this.month_of_items[indexMonth].month_of
        array.push(
          [
            {text: 'District/Club', alignment: 'center', style: 'label'},
            {text: 'Points for Health', alignment: 'center', style: 'label'},
            {text: 'Points for Education', alignment: 'center', style: 'label'},
            {text: 'Points for Community', alignment: 'center', style: 'label'},
            {text: 'Points for Livelihood', alignment: 'center', style: 'label'},
            {text: 'Points for Youth', alignment: 'center', style: 'label'},
            {text: 'Points for Environment', alignment: 'center', style: 'label'},
            {text: 'Points for Protection', alignment: 'center', style: 'label'},
            {text: 'Total', alignment: 'center', style: 'label'},
          ]
        )

        if (this.list_of_accomplishment.length > 0) {
          this.list_of_accomplishment.forEach(function (item, index) {
            array.push(
              [
                {text: item.district_name  , alignment: 'left'},
                {text: item.Health , alignment: 'center'},
                {text: item.Education , alignment: 'center'},
                {
                  text: item.Community ,
                  alignment: 'center'
                },
                {text: item.Livelihood, alignment: 'center'},
                {text: item.Youth, alignment: 'center'},
                {text: item.Environment, alignment: 'center'},
                {text: item.Protection, alignment: 'center'},
                {text: item.total, alignment: 'center'},
              ]
            )
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'landscape',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE COMMUNITY CARE FOUNDATION',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C REG. No. NA CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LIST OF POINTING SYSTEM', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Month of: ' + month, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [200, 55, 55, 55, 55,55,55,55,55],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          // footer: {
          //   columns: [
          //     {
          //       text: 'Generated with Goodlife Savings & Credit Cooperative System',
          //       alignment: 'center',
          //       style: 'tableExample'
          //     }
          //   ]
          // },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
